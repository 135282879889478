<template>
  <div>
    <homeMb v-if="screenWidth < 765" class="is-mb" />
    <homePc v-else class="is-pc" />
  </div>
</template>

<script>
// 修改手机端为原来的样式
import homePc from '@/views_pc/home/index-test'
import homeMb from '@/views_mb/home/index-test'
export default {
  components: { homePc, homeMb },
  data() {
    return {
      screenWidth: document.body.clientWidth
    }
  },  
  created () {
    window.location.href = 'https://travelcontentsapp.com/';
  },
  mounted() {
    const that = this
    window.onresize= () => {
        return (() => {
            window.screenWidth = document.body.clientWidth
            that.screenWidth = window.screenWidth
        })()
    }
  },
  watch: {
    screenWidth(val) {
      this.screenWidth = val
      // console.log(val)
    }
  },
  methods: {}
}
</script>
<style lang='scss' scoped>
.is-pc {
  display: block;
}
.is-mb {
  display: none;
}
@media(max-width:766px) {
  .is-pc {
    display: none;
  }
  .is-mb {
    display: block;
  }
}
</style>
